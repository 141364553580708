<template>
  <div class="card card-custom card-stretch">
    <div class="card-header ribbon ribbon-clip ribbon-right">
      <div class="ribbon-target" style="top: 15px; height: 45px;" v-if="user.roles[0].authority == 'FACILITATOR'|| user.roles[0].authority == 'ADMIN'">
        <span class="ribbon-inner bg-warning" ></span><i class="fas fa-crown text-white"></i>
      </div>
      <div class="ribbon-target" style="top: 15px; height: 45px;" v-else-if="user.roles[0].authority == 'ASSESSOR' || user.roles[0].authority == 'EXPERT'">
        <span class="ribbon-inner bg-warning" ></span><i class="fas fa-pen text-white"></i>
      </div>
      <div class="ribbon-target" style="top: 15px; height: 45px;" v-else-if="user.roles[0].authority == 'DELEGATE'">
        <span class="ribbon-inner bg-warning" ></span><i class="fas fa-star text-white"></i>
      </div>
      <div class="ribbon-target" style="top: 15px; height: 45px;" v-else-if="user.roles[0].authority == 'READER'">
        <span class="ribbon-inner bg-primary" ></span><i class="fas fa-search text-white"></i>
      </div>
      <div class="ribbon-target" style="top: 15px; height: 45px;" v-else>
        <span class="ribbon-inner bg-primary" ></span><i class="fas fa-lightbulb text-white"></i>
      </div>
      <div class="card-title" style="font-size: 1em;" v-for="item in user.roles" :key="item.id">
        <router-link :to="{ name: 'userDetail', params: { id: user.id, group_id: group_id } }" v-if="user.profilePublic == true">
          {{item.name}}
        </router-link>
        <span :to="{ name: 'userDetail', params: { id: user.id, group_id: group_id } }" v-else>
          Profilo Privato
        </span>
      </div>
    </div>
    <div class="card-body text-center">
      <div class="d-none d-md-block py-1" style="max-height: 230px; min-height: 230px; overflow: hidden; position: relative;">
        <router-link :to="{ name: 'userDetail', params: { id: user.id, group_id: group_id } }" v-if="user.profilePublic == true">
          <img
              :src="(user.avatar && user.avatar.uri) ? user.avatar.uri  : $imageOptions.defaultAvatarUrl"
              class="align-self-center w-100"
              style="position: absolute; margin: auto; top: 0;left: 0;right: 0;"
              alt="Avatar"
          />
        </router-link>
        <span v-else>
          <img
              :src="$imageOptions.defaultLogoUrl"
              class="align-self-center w-100"
              style="position: absolute; margin: auto; top: 0;left: 0;right: 0;"
              alt="Avatar"
          />
        </span>
      </div>
      <div class="mt-3 text-dark font-weight-bolder text-hover-primary font-size-h5 text-left">
        <span v-if="user.profilePublic == true"><router-link :to="{ name: 'userDetail', params: { id: user.id, group_id: group_id } }">{{user.name}} {{user.surname}}</router-link></span>
        <span v-else>Il nome dell'utente è privato</span>
      </div>
    </div>
  </div>
</template>

<script>



export default {
  name: "UserCard",
  props: ["user", "index", "group_id"]
};
</script>

<style scoped></style>
