<template>
  <div class="row h-100  justify-content-center">

    <!-- <div class="d-none d-lg-block col-lg-2 col-xl-2 text-white bg-primary">
       <div class="pl-4">
       <OrganizationMenu></OrganizationMenu>
       </div>
    </div> -->
    <div class="col-lg-10 col-xl-10 justify-content-start">
      <div class="d-flex flex-column px-7 mt-15">
        <div class="row align-items-center">
          <div class="col-12 d-flex justify-content-start">
            <div class="d-flex flex-column">
                <h2 class="card-label h1 font-weight-bolder">{{$t("GROUPDETAIL.GRUPPO")}} |  {{ group }}</h2>
                <h3 class="mt-2">{{$t("GROUPDETAIL.PUBBLICA")}} </h3>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column px-6"> <!--v-if="users.length > 0"-->

        <!--::Module Filter
        <div class="card card-custom mt-3">
          <b-nav>
            <b-nav-item active>
              <b-dropdown id="dropdown-filter-role" text="filtra per ruolo" variant="transparent">
                <b-dropdown-item>
                  <i><a href="#" v-on:click="filterUsersByRole($event, 0)" >Rimuovi filtro</a></i>
                </b-dropdown-item>
                <b-dropdown-item v-for="item in roles" :key="item.id">

                  <a href="#" v-on:click="filterUsersByRole($event, item.id)" >{{ item.name }}</a>
                </b-dropdown-item>
              </b-dropdown>
            </b-nav-item>
            <b-nav-item>
              <b-dropdown id="dropdown-filter-role" text="filtra per genere" variant="transparent">
                <b-dropdown-item class="btn-white">
                  <a href="#" v-on:click="filterUsersByGender($event, 0)" >Rimuovi filtro</a>
                </b-dropdown-item>
                <b-dropdown-item v-for="item in genders" :key="item.key">
                  <a href="#" v-on:click="filterUsersByGender($event, item.key)" >{{ item.value }}</a>
                </b-dropdown-item>
              </b-dropdown>
            </b-nav-item>

          </b-nav>
        </div>-->

         <!--begin::Search-->
        <form method="get" class="quick-search-form">
          <div class="input-group px-4">
            <div class="input-group-prepend">
              <button
                class="input-group-text line-height-0 py-0 border-0 bg-transparent"
                v-on:click="getSearchData($event)"
              >
                <img
                  src="/assets/media/bipart/ico-search.png"
                  alt=""
                  class="w-100"
                />
              </button>
            </div>
            <input
              type="text"
              class="form-control bg-transparent py-10 h4 border-0"
              placeholder="Search..."
              v-model="keywords"
            />

            <div class="input-group-append">
              <span class="input-group-text h3 px-10 text-primary font-weight-bold bg-transparent border-0">
                {{usersCount}}<span class="text-muted h6 ml-4">{{$t("GROUPDETAIL.MEMBRI")}}</span>
              </span>
            </div>
          </div>
        </form>



        <!--begin::Elenco -->
        <div class="mt-3">
          <div
            class="row gutter-b"
          >

            <div
              id="my-users"
              v-for="(item, index) in users"
              :key="item.id"
              class="col-12 col-md-4 col-xl-3 mb-7"
            >
              <UserCard :user="item" :index="index" :group_id="id"> </UserCard>
            </div>
          </div>
        </div>
      </div>

      <infinite-loading @infinite="loadMore" spinner="bubbles" v-if="this.currentPage < this.pageCount"></infinite-loading>

    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import UserCard from "./partials/UserCard";
import InfiniteLoading from 'vue-infinite-loading';
import { globalMixin } from "@/mixins/global";

export default {
  name: "GroupDetail",
  mixins: [globalMixin],
  components: {
    UserCard,
    InfiniteLoading
  },
  static: {
    visibleItemsPerPageCount: 8
  },
  data() {
    return {
      usersCount: 0,
      currentPage: 1,
      pageCount: 0,
      group: this.$route.params.group_name,
      id: this.$route.params.id,
      users: [],
      roles: [],
      genders: [
        {
          "key": "F",
          "value": this.$t("GROUPDETAIL.F"),
        },
        {
          "key": "M",
          "value": this.$t("GROUPDETAIL.M"),
        }
      ],
      keywords: ""
    };
  },
  computed: {},
  mounted() {
    // set the tab from previous
    // this.setActivePreviousTab();

    this.getData();
    //this.getRoles()

    // necessario per la valorizzazione corretta della rotta "detail"
    setTimeout(
      () =>
        this.$store.dispatch(SET_BREADCRUMB, [
          { title: this.$t("GROUPDETAIL.ELENCOGRUPPI"), route: "/group-list" },
          { title: this.$t("GROUPDETAIL.GRUPPO") }
        ]),
      200
    );
  },
  created() {},
  methods: {
    loadMore($state) {

      setTimeout(() => {

        if(this.currentPage < this.pageCount) {

          this.currentPage += 1

          return new Promise((resolve, reject) => {
            let qs = "?groupId.equals=" + this.id + "&profilePublic.equals=true&size=" + this.$options.static.visibleItemsPerPageCount + "&page=" + this.currentPage;

            if (this.keywords) qs += "&fullName.contains=" + this.keywords;

            var endpoint = this.generateUrl("users-page", true);
            //generateUrl is done
            ApiService.get(this.$apiResource, endpoint + qs)
              .then(res => {

                if(res.data.content != null) {

                  res.data.content = this.shuffle(res.data.content);

                  res.data.content.forEach(element => {
                    this.users.push(element);
                  })

                  this.usersCount = res.data.meta.total;
                  this.pageCount = res.data.meta.pages;
                }

                $state.loaded();
              })
              .catch(() => {
                reject(0);
              })
          });

        } else {

          $state.loaded();
        }
      });
    },
    getSearchData(event) {
      event.preventDefault();
      this.getData();
    },
    getData() {
      return new Promise((resolve, reject) => {
        this.$isLoading(true);

        let qs = "?groupId.equals=" + this.id + "&profilePublic.equals=true&size=" + this.$options.static.visibleItemsPerPageCount + "&page=" + this.currentPage;

        if (this.keywords) qs += "&fullName.contains=" + this.keywords;

        var endpoint = this.generateUrl("users-page", true);
        //generateUrl is done
        ApiService.get(this.$apiResource, endpoint + qs)
          .then(res => {
            this.usersCount = res.data.meta.total;
            this.pageCount = res.data.meta.pages;
            res.data.content = this.shuffle(res.data.content);
            this.users = res.data.content;
            this.$isLoading(false);
          })
          .catch(() => {
            this.$isLoading(false);
            reject(0);
          })

        /*ApiService.get(this.$apiResource, "groups-expanded/" + this.id + "/locs/it")
          .then(res => {
            this.group = res.data;
            this.users = this.group.users;
            this.$isLoading(false);
          })
          .catch(() => {
            this.$isLoading(false);
            reject(0);
          });*/

      });
    },
    getRoles() {
      return new Promise((resolve, reject) => {
        var endpoint = this.generateUrl("roles", true);
        //generateUrl is done
        ApiService.get(this.$apiResource, endpoint)
            .then(res => {
              // console.log(res.data);
              this.roles = res.data;
            })
            .catch(() => {
              reject(0);
            });
      });
    },

    /*filterUsersByRole ( e, role_id ) {
      e.preventDefault();
      if(role_id == 0) {
        this.getData();
        return;
      }
      this.users = this.group.users.filter( user => {
          let res = user.roles.find( item => {
              return item['id'] == role_id;
          });
          return (res) ? true : false;
      });
    },

    filterUsersByGender ( e, gender ) {
      e.preventDefault();
      if(gender == 0) {
        this.getData();
        return;
      }
      this.users = this.group.users.filter( user => {
        return (user['gender'] == gender);
      });
    },

    orderBySurname(event) {
      event.preventDefault();
      this.users.sort((a,b) => (a.surname > b.surname) ? 1 : -1)
    }*/
  }
};
</script>

<style scoped></style>
